import { ChevronRightIcon } from "@heroicons/react/outline";
import { UserDetailsReturnProps } from "../../screens/User/Details/types";
import Button from "../common/Button";
import NoteTableBody from "./NoteTableBody";

type NoteTableType = Pick<
  UserDetailsReturnProps,
  "hasMoreNotes" | "loadMoreSecurityNotes" | "securityNotes" | "isFetchingMore"
>;
const NoteTable = ({
  securityNotes,
  hasMoreNotes,
  loadMoreSecurityNotes,
  isFetchingMore,
}: NoteTableType) => {
  return (
    <div className="w-full overflow-x-auto mt-3">
      <div className="min-h-min max-h-96 overflow-y-auto border">
        <table className="min-w-full table-auto">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                Date
              </th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                Initiator
              </th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                Note
              </th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                Reason
              </th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                Action
              </th>
            </tr>
          </thead>
          <NoteTableBody securityNotes={securityNotes} />
        </table>
      </div>

      <div className="flex justify-end mt-4">
        {hasMoreNotes && (
          <Button
            variant="outline"
            colorScheme="cyan"
            onClick={loadMoreSecurityNotes}
            disabled={isFetchingMore}
            rightIcon={
              !isFetchingMore && <ChevronRightIcon className="w-5 h-5" />
            }
            className="px-4 py-2 rounded"
            isLoading={isFetchingMore}
          >
            View More
          </Button>
        )}
      </div>
    </div>
  );
};

export default NoteTable;
