import { useEffect, useState } from "react";
import { AfriexPerson } from "../../../types";

import Receipt from "../../../components/Receipt";
import { fileService, kycBucketKey } from "../../../services/fileService";
import { isEmpty } from "lodash";
import Spinner from "../../../assets/svg/Spinner";

const KycReceipt = ({ data }: { data: AfriexPerson }) => {
  const kycImages = data.kyc?.images;
  const [base64Images, setBase64Images] = useState<{
    front?: string | null;
    back?: string | null;
    selfie?: string | null;
  }>({});
  const [imagesLoading, setImagesLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      if (!data?.id || !kycImages) return;

      try {
        const imageResponses = await Promise.allSettled([
          fileService.s3ToBase64(
            String(kycImages.back),
            kycBucketKey,
            data?.id
          ),
          fileService.s3ToBase64(
            String(kycImages.front),
            kycBucketKey,
            data?.id
          ),
          fileService.s3ToBase64(
            String(kycImages.selfie),
            kycBucketKey,
            data?.id
          ),
        ]);

        const [front, back, selfie] = imageResponses.map((result) =>
          result.status === "fulfilled" ? result.value : null
        );

        setBase64Images({ front, back, selfie });
        setImagesLoading(false);
      } catch (error) {
        console.error("Error fetching images:", error);
        setImagesLoading(false);
      }
    };

    fetchImages();
  }, [data?.id, kycImages]);

  if (isEmpty(base64Images)) {
    return (
      <>
        {imagesLoading ? (
          <Spinner size={8} className="text-cyan-600" />
        ) : (
          <div className="main flex flex-col bg-receipt-bg p-5 mt-5 gap-4 text-cyan-600">
            No Kyc documents
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <Receipt type="kyc">
        <div className="main flex flex-col bg-receipt-bg p-5 mt-5 gap-4">
          {base64Images.front && (
            <div>
              <img src={base64Images.front} alt="Front ID" />
            </div>
          )}
          {base64Images.back && (
            <div>
              <img src={base64Images.back} alt="Back ID" />
            </div>
          )}
          {base64Images.selfie && (
            <div>
              <img src={base64Images.selfie} alt="Selfie" />
            </div>
          )}
        </div>
      </Receipt>
    </>
  );
};

export default KycReceipt;
