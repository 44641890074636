import { Link, useParams } from "react-router-dom";
import { Button, Header } from "../../../components";
import { AfriexKYCProcessor, AfriexVerificationStatus } from "../../../types";
import KYCIDPrimary from "./KYCIDPrimary";
import KYCIDSecondary from "./KYCIDSecondary";
import KYCUpdateButton from "./KYCUpdateButton";
import KYCVerificationView from "./KYCVerificationView";
import useKYCDetails from "./useKYCDetails";
import KYCRequestedDcuments from "./KYCRequestedDocuments";
import { isEmpty } from "lodash";
import VirtualAccountApplicationView from "./VirtualAccountApplicationView";
import Summary from "./Summary";
import VirtualAccountInfoView from "./VirtualAccountInfoView";
import { ExternalLinkIcon } from "@heroicons/react/outline";

const KYCDetails = () => {
  const params = useParams();
  const userId = params?.userId ?? "";
  const {
    isLoadingUserDetails,
    handleVerify,
    handleSubmission,
    handleDeleteSession,
    user,
    virtualAccountApplication,
    virtualAccountInfo,
  } = useKYCDetails(userId);
  const isVerified = user?.kyc?.status === AfriexVerificationStatus.Success;
  const isKYCUploaded = Boolean(user?.kyc?.primary?.idNumber);
  const verifyLabel = isVerified ? "Unverify" : "Mark as verified";
  const hasTooManyRetries =
    (user?.kyc?.verification?.veriff?.attempts ?? 0) >= 1;

  return isLoadingUserDetails ? (
    <div>Loading...</div>
  ) : (
    <div className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title={`${user.name?.fullName ?? ""} KYC Details`} />

      {/* Top Buttons */}
      <div className="w-full p-3 flex gap-2 justify-between">
        <div className="flex gap-2">
          <Button
            disabled={!isKYCUploaded}
            colorScheme="cyan"
            variant="outline"
            onClick={() => handleVerify(!isVerified)}
          >
            {verifyLabel}
          </Button>

          <KYCUpdateButton user={user} isKYCUploaded />

          <Button
            disabled={!isKYCUploaded || isVerified || hasTooManyRetries}
            colorScheme="cyan"
            variant="outline"
            onClick={() => {
              handleSubmission(AfriexKYCProcessor.Veriff);
              return false;
            }}
          >
            Retry Veriff Verification
          </Button>
        </div>

        <div>
          {!isEmpty(user?.kyc?.images) && (
            <Link
              to={`/kyc/${params?.userId}/receipt`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                colorScheme="cyan"
                variant="outline"
                className="bg-indigo-200"
                leftIcon={<ExternalLinkIcon className="w-4 h-4" />}
              >
                View Receipt
              </Button>
            </Link>
          )}
        </div>
      </div>

      <KYCRequestedDcuments user={user} />

      <Summary user={user} />

      <KYCIDPrimary user={user} />

      {user?.kyc?.secondary?.idNumber && <KYCIDSecondary user={user} />}

      {/* Virtual Account Information */}
      {!isEmpty(virtualAccountApplication) && (
        <VirtualAccountApplicationView data={virtualAccountApplication} />
      )}

      {/* US -> UK - EU */}
      {!isEmpty(virtualAccountInfo) && (
        <VirtualAccountInfoView data={virtualAccountInfo} />
      )}

      {Object.keys(user?.kyc?.verification ?? {})?.map((processor, idx) => (
        <KYCVerificationView
          key={idx}
          processor={processor as AfriexKYCProcessor}
          handleDeleteSession={handleDeleteSession}
          verification={
            (user?.kyc?.verification as any)?.[processor] ?? ({} as any)
          }
        />
      ))}
    </div>
  );
};

export default KYCDetails;
